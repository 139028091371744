<template>
    <section
        class="PromotionBanner"
        :class="{ 'PromotionBanner--in-view': promotionBannerInView }"
        ref="promotionBanner"
    >
        <SmartPicture
            class="PromotionBanner__image"
            :is-background="true"
            :small-image="slice.primary.image.mobile.url"
            :medium-image="slice.primary.image.tablet.url"
            :large-image="slice.primary.image.url"
        />
        <div class="PromotionBanner__content">
            <h2 v-if="slice.primary.title" class="PromotionBanner__title">
                {{ slice.primary.title }}
            </h2>
            <PrismicRichText
                v-if="slice.primary.description.length"
                class="PromotionBanner__desc"
                :field="slice.primary.description"
            />
            <CustomButton
                v-if="
                    slice.primary.button_text &&
                    (slice.primary.button_internal_link.url ||
                        slice.primary.button_external_link.url)
                "
                :to="slice.primary.button_internal_link.url"
                :href="slice.primary.button_external_link.url"
                class="PromotionBanner__btn"
            >
                {{ slice.primary.button_text }}
            </CustomButton>
        </div>
    </section>
</template>

<script setup lang="ts">
import { type Content } from '@prismicio/client'

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
defineProps(
    getSliceComponentProps<Content.PromotionSlice>([
        'slice',
        'index',
        'slices',
        'context',
    ]),
)

const promotionBannerObserver = ref<IntersectionObserver>()
const promotionBannerInView = ref(false)
const promotionBanner = ref()

onMounted(() => {
    promotionBannerObserver.value = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    promotionBannerInView.value = true
                    observer.disconnect()
                }
            })
        },
        {
            threshold: 0.5,
        },
    )

    if (promotionBanner.value)
        promotionBannerObserver.value.observe(promotionBanner.value)
})
</script>

<style lang="scss" scoped>
.PromotionBanner {
    position: relative;
    grid-column: 2 / span 22;
    margin-top: 8.75rem;
    overflow: hidden;

    @include medium-up {
        margin-top: 11.25rem;
    }
}

.PromotionBanner__content {
    position: relative;
    text-align: center;
    padding-top: 30%;
    padding-bottom: 30%;

    @include medium-only {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    @include large-up {
        padding-top: 5%;
        padding-bottom: 5%;
        max-width: 51.25rem;
        margin-left: auto;
        margin-right: auto;
    }
}

.PromotionBanner__title {
    @include heading-2;
    @include upwards-animate-in-setup;
}

.PromotionBanner__desc {
    @include body-lg;
    @include upwards-animate-in-setup;

    max-width: 51.25rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;

    @include large-up {
        margin-top: 0.25rem;
    }
}

.PromotionBanner__btn {
    @include upwards-animate-in-setup;

    margin-top: 1.25rem;
}

.PromotionBanner__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.05);
    transition: transform 0.8s ease;

    &::after {
        content: '';
        opacity: 0;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(
            80% 80% at 50% 50%,
            rgba(0, 0, 0, 0.7) 0%,
            rgba(0, 0, 0, 0) 100%
        );
        transition: opacity 0.8s 0.5s ease;
    }
}

/**
    Modifiers
*/
.PromotionBanner--in-view {
    .PromotionBanner__image {
        transform: scale(1);

        &:after {
            opacity: 1;
        }
    }

    .PromotionBanner__title {
        @include upwards-animate-in($duration: 0.6s, $delay: 0.6s);
    }

    .PromotionBanner__desc {
        @include upwards-animate-in($duration: 0.6s, $delay: 0.7s);
    }

    .PromotionBanner__btn {
        @include upwards-animate-in($duration: 0.6s, $delay: 0.8s);
    }
}
</style>
